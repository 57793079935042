<template>
  <div>
    <h2 class="mb-4">Создание партнера</h2>
    <page-section subheader="Атрибуты">
      <ValidationObserver ref="form">
        <v-row>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-text-field
                v-model="form.name"
                label="Название *"
                placeholder="Название *"
                background-color="white"
                outlined
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" rules="url">
              <v-text-field
                v-model="form.url"
                label="Сайт"
                placeholder="Сайт"
                background-color="white"
                :error-messages="errors"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ImageInput v-model="form.logotype" label="Логотип партнера" />
          </v-col>
        </v-row>
      </ValidationObserver>
    </page-section>
    <PageControlsBar ref="controls">
      <div class="d-flex"></div>
      <v-btn
        color="primary"
        class="mr-4"
        :disabled="isLoading"
        depressed
        @click="onSave"
      >
        Сохранить
      </v-btn>
      <v-btn
        class="mr-4"
        text
        @click="$router.push({ name: 'admin/partners' })"
      >
        Отменить
      </v-btn>
    </PageControlsBar>
  </div>
</template>

<script>
import PageSection from "@components/page/Section.vue";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import PageControlsBar from "@components/page/ControlsBar.vue";
import { CreatePartner } from "@/graphql/queries/Partners.graphql";
import { Routes } from "@/types/core";

export default {
  components: {
    PageSection,
    ImageInput,
    PageControlsBar,
  },

  data() {
    return {
      form: {
        name: "",
        url: "",
        logotype: null,
      },
      isLoading: false,
    };
  },

  created() {
    if (!this.$store.getters["session/isAllow"]("ADD_ITEM"))
      this.$router.push({ name: Routes["noRights"] });
  },

  methods: {
    async onSave() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: CreatePartner,
          variables: {
            input: {
              name: this.form.name,
              url: this.form.url,
              logotype: this.form.logotype,
            },
          },
        });

        this.$router.push({ name: Routes["admin/partners"] });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
